<template>
  <v-container>
    <v-row>
      <v-col class="mt-14" cols="12">
        <h2>{{$t('help.title')}}</h2>
      </v-col>
    </v-row>
    <v-row class="mt-5" >
      <v-col cols="12" v-if="userRoles && userRoles[0] !== 'ROLE_USER'">
        <h4>First Steps </h4>
        <p>As soon as you have successfully registered as a new customer admin,
          you can immediately start preparing to work with VisionCockpit</p>
      <v-expansion-panels  multiple v-model="openedPanel.acc0">
        <v-expansion-panel
          class="elevation-1"
          v-for="(item,i) in first_step"
          :key="i"
          :id="'acc0_' + i"
        >
          <v-expansion-panel-header>
            {{ item.title }}
          </v-expansion-panel-header>
          <v-expansion-panel-content  ref="link_customertrainings" >
            <p class="mb-3" v-html="item.description_1"></p>
            <img :src="imageUrl(item.img_1)"
                 :alt="item.img_1"
                 :class="['img-responsive', getImageWidthClass(item.img_1)]"
            />
            <p class="mb-3" v-html="item.description_2"></p>
            <img :src="imageUrl(item.img_2)"
                 :alt="item.img_2"
                 :class="['img-responsive', getImageWidthClass(item.img_2)]"
            />
            <p class="mb-3" v-html="item.description_3"></p>
            <p class="mb-3" v-if="item.link">
              See
              <a
                @click="scrollTo('acc1', 1)">
                {{ item.link.title }}</a>
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
      <v-col cols="12">
        <h4>General</h4>
        <p>General guidance on how to use VisionCockpit</p>
        <v-expansion-panels multiple v-model="openedPanel.acc1">
          <v-expansion-panel
            class="elevation-1"
            v-for="(item,i) in data"
            :key="i"
            :id="'acc1_' + i"
          >
            <v-expansion-panel-header>
              {{ item.title }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p class="mb-3 margin-br" v-html="item.description"></p>
              <img v-if="item.img"
                   :class="['mr-3 img-responsive', getImageWidthClass(item.img)]"
                   :src="imageUrl(item.img)"
                   :alt="item.img"
              />
              <p v-if="item.description_2" class="mb-3 margin-br" v-html="item.description_2"></p>
              <img v-if="item.img_1"
                   :class="['mr-3 img-responsive', getImageWidthClass(item.img_1)]"
                   :src="imageUrl(item.img_1)"
                   :alt="item.img_1"
              />
              <p v-if="item.description_3" class="mb-3 margin-br" v-html="item.description_3"></p>
              <ul v-if="item.list && item.list.length">
               <li v-for="(list, index) in item.list" :key="index">
                 {{list}}
               </li>
              </ul>
              <div v-if="item.file_menager">
                <div v-for="(child, index) in item.child" :key="index">
                  <p v-if="child.title" class="mb-2 blue-text font-weight-bold"
                     v-html="child.title"></p>
                  <div class="d-flex mb-5">
                    <img v-if="child.img"
                         :class="['mr-3 img-responsive', getImageWidthClass(child.img)]"
                         :src="imageUrl(child.img)"
                         :alt="child.img"
                    />
                    <p v-if="child.description || child.link" class="mb-3 margin-br"
                    ><span v-if="child.description" v-html="child.description"></span>
                      <span v-if="child.link"> (see
                      <a v-if="child.link"
                        @click="scrollTo(child.link.targetAccIndex, child.link.targetPanelIndex)">
                        {{ child.link.title }}</a>).
                    </span></p>
                  </div>
                </div>
              </div>
              <div v-if="item.labelling_tool">
                <div v-for="(child, index) in item.child" :key="index">
                  <p v-if="child.title" class="mb-2 mt-5 blue-text font-weight-bold"
                     v-html="child.title">
                  </p>
                  <p v-if="child.description" class="mb-3 margin-br" v-html="child.description"></p>
                  <p v-if="child.description_1" class="mb-3 margin-br" v-html="child.description_1"></p>
                  <img v-if="child.img_1"
                    :class="['mr-3 img-responsive', getImageWidthClass(child.img_1)]"
                    :src="imageUrl(child.img_1)"
                    :alt="child.img_1"
                  />
                  <p v-if="child.description_2" class="mb-3 margin-br" v-html="child.description_2"></p>
                  <p v-if="child.description_3" class="mb-3 margin-br" v-html="child.description_3"></p>
                  <p v-if="child.description_4" class="mb-3 margin-br" v-html="child.description_4"></p>
                  <img v-if="child.img_2"
                    :class="['mr-3 img-responsive', getImageWidthClass(child.img_2)]"
                    :src="imageUrl(child.img_2)"
                    :alt="child.img_2"
                  />
                  <div class="d-flex mt-2">
                    <img v-if="child.img_3"
                      :class="['mr-6 img-responsive', getImageWidthClass(child.img_3)]"
                      :src="imageUrl(child.img_3)"
                      :alt="child.img_3"
                    />
                    <p v-if="child.description_5" class="mb-3 margin-br" v-html="child.description_5"></p>
                  </div>
                  <img v-if="child.img_4"
                    :class="['mr-3 mt-5 img-responsive', getImageWidthClass(child.img_4)]"
                    :src="imageUrl(child.img_4)"
                    :alt="child.img_4"
                  />
                  <p v-if="child.description_6" class="mb-3 margin-br" v-html="child.description_6"></p>
                  <div class="d-flex">
                    <img v-if="child.img_5"
                      :class="['mr-3 img-responsive', getImageWidthClass(child.img_5)]"
                      :src="imageUrl(child.img_5)"
                      :alt="child.img_5"
                    />
                    <p v-if="child.description_7" class="mb-3 margin-br" v-html="child.description_7"></p>
                  </div>
                  <div class="d-flex mb-5">
                    <img v-if="child.img_6"
                      :class="['mr-3 img-responsive', getImageWidthClass(child.img_6)]"
                      :src="imageUrl(child.img_6)"
                      :alt="child.img_6"
                    />
                    <p v-if="child.description_8" class="mb-3 margin-br" v-html="child.description_8"></p>
                  </div>
                  <p v-if="child.description_9" class="mb-3 margin-br" v-html="child.description_9"></p>
                  <p v-if="child.description_10" class="mb-3 margin-br" v-html="child.description_10"></p>
                  <img v-if="child.img_7"
                    :class="['mr-3 img-responsive', getImageWidthClass(child.img_7)]"
                    :src="imageUrl(child.img_7)"
                    :alt="child.img_7"
                  />
                  <p v-if="child.description_11" class="mb-3 margin-br" v-html="child.description_11"></p>
                </div>
              </div>
              <template v-if="item.second_accordion">
                <v-expansion-panels class="second-panels">
                  <v-expansion-panel
                    class="elevation-1"
                    v-for="(it,i) in item.child"
                    :key="i"
                  >
                    <v-expansion-panel-header>
                      {{ it.title }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <p v-if="it.description" class="mb-3 margin-br" v-html="it.description"></p>
                      <img v-if="it.img" :src="imageUrl(it.img)"
                           :alt="it.img"
                           :class="['img-responsive', getImageWidthClass(it.img)]"
                      />
                      <p v-if="it.description_2" class="mb-3 margin-br"
                         v-html="it.description_2"></p>
                      <img v-if="it.img_1" :src="imageUrl(it.img_1)"
                           :alt="it.img_1"
                           :class="['img-responsive', getImageWidthClass(it.img_1)]"
                      />
                      <p v-if="it.description_3" class="mb-3 margin-br"
                         v-html="it.description_3"></p>
                      <p v-if="it.description_4" class="mb-3 margin-br"
                         v-html="it.description_4"></p>
                      <div v-for="(section, sectionId) in 7" :key="sectionId">
                        <div v-if="it['section_' + section]" class="my-3">
                          <p v-if="it['section_' + section].title" class="mb-2 blue-text" >
                            <strong>{{ it['section_' + section].title }}</strong>
                          </p>
                          <p v-if="it['section_' + section].description">
                            <span class="margin-br"
                                  v-html="it['section_' + section].description"></span>
                          </p>
                          <img v-if="it['section_' + section].img"
                               :src="imageUrl(it['section_' + section].img)"
                               :alt="it['section_' + section].img"
                               :class="['img-responsive',
                               getImageWidthClass(it['section_' + section].img)]"
                          />
                          <img v-if="it['section_' + section].img_2"
                               :src="imageUrl(it['section_' + section].img_2)"
                               :alt="it['section_' + section].img_2"
                               :class="['img-responsive',
                               getImageWidthClass(it['section_' + section].img_2)]"
                          />
                          <p v-if="it['section_' + section].description_2">
                            <span class="margin-br"
                                  v-html="it['section_' + section].description_2"></span>
                          </p>
                          <img v-if="it['section_' + section].img_3"
                               :src="imageUrl(it['section_' + section].img_3)"
                               :alt="it['section_' + section].img_3"
                               :class="['img-responsive',
                               getImageWidthClass(it['section_' + section].img_3)]"
                          />
                          <ul v-if="checkList(it, section)" class="inside-list">
                            <li v-for="(list, index) in it['section_' + section].list" :key="index">
                                <span>{{list.title}}</span>
                                {{list.text}}
                            </li>
                          </ul>
                          <p class="my-3" v-if="it['section_' + section].info"
                             v-html="it['section_' + section].info">
                          </p>
                          <img v-if="it['section_' + section].img_4"
                               :src="imageUrl(it['section_' + section].img_4)"
                               :alt="it['section_' + section].img_4"
                               :class="['img-responsive',
                               getImageWidthClass(it['section_' + section].img_4)]"
                          />
                        </div>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'HelpPage',
  data() {
    return {
      imageWidths: {},
      userRoles: null,
      first_step: [
        {
          title: this.$t('help.create_user.title'),
          description_1: this.$t('help.create_user.description_1'),
          img_1: 'Userlist-1.png',
          description_2: this.$t('help.create_user.description_2'),
          img_2: 'Userlist-2.png',
          description_3: this.$t('help.create_user.description_3'),
        },
        {
          title: this.$t('help.setup_machines.title'),
          description_1: this.$t('help.setup_machines.description_1'),
          img_1: 'CreateMachines-1.png',
          description_2: this.$t('help.setup_machines.description_2'),
          img_2: 'MachineFileStructure.png',
          description_3: this.$t('help.setup_machines.description_3'),
          link: {
            title: 'Trainings',
            targetAccIndex: 'acc1',
            targetPanelIndex: 1,
          },
        },
      ],
      data: [
        {
          title: this.$t('help.what_is.title'),
          description: this.$t('help.what_is.description'),
          list: [
            this.$t('help.what_is.list.text_1'),
            this.$t('help.what_is.list.text_2'),
            this.$t('help.what_is.list.text_3'),
            this.$t('help.what_is.list.text_4'),
            this.$t('help.what_is.list.text_5'),
            this.$t('help.what_is.list.text_6'),
            this.$t('help.what_is.list.text_7'),
          ],
        },
        {
          title: this.$t('help.trainings.title'),
          second_accordion: true,
          child: [
            {
              title: this.$t('help.trainings.child.title_1'),
              description: this.$t('help.trainings.child.description_1'),
              img: 'MachineFileStructure.png',
            },
            { title: this.$t('help.trainings.child.title_2'), description: this.$t('help.trainings.child.description_2') },
            {
              title: this.$t('help.trainings.child.title_3'),
              description: this.$t('help.trainings.child.description_3'),
              section_1: {
                title: this.$t('help.trainings.child.section_1.title'),
                description: this.$t('help.trainings.child.section_1.description'),
              },
              section_2: {
                title: this.$t('help.trainings.child.section_2.title'),
                description: this.$t('help.trainings.child.section_2.description'),
                list: [
                  this.$t('help.trainings.child.section_2.list.text_1'),
                  this.$t('help.trainings.child.section_2.list.text_2'),
                  this.$t('help.trainings.child.section_2.list.text_3'),
                  this.$t('help.trainings.child.section_2.list.text_4'),
                ],
              },
              section_3: {
                title: this.$t('help.trainings.child.section_3.title'),
                description: this.$t('help.trainings.child.section_3.description'),
              },
              section_4: {
                title: this.$t('help.trainings.child.section_4.title'),
                list: [
                  {
                    title: this.$t('help.trainings.child.section_4.list.text_1.title'),
                    text: this.$t('help.trainings.child.section_4.list.text_1.text'),
                  },
                  {
                    title: this.$t('help.trainings.child.section_4.list.text_2.title'),
                    text: this.$t('help.trainings.child.section_4.list.text_2.text'),
                  },
                  {
                    title: this.$t('help.trainings.child.section_4.list.text_3.title'),
                    text: this.$t('help.trainings.child.section_4.list.text_3.text'),
                  },
                ],
                info: this.$t('help.trainings.child.section_4.info'),
              },
            },
            {
              title: this.$t('help.trainings.child.title_4'),
              description: this.$t('help.trainings.child.description_4'),
              img: 'StartTraining-1.png',
              description_2: this.$t('help.trainings.child.description_4_part_2'),
              img_1: 'StartTraining-2.png',
              section_1: {
                title: this.$t('help.trainings.child.section_5.title'),
                description: this.$t('help.trainings.child.section_5.description'),
                img: 'training_error.png',
                description_2: this.$t('help.trainings.child.section_5.description_2'),
              },
            },
            {
              title: this.$t('help.trainings.child.title_5'),
              description: this.$t('help.trainings.child.description_5'),
              img: 'ModelDownload.png',
            },
            {
              title: this.$t('help.trainings.child.title_error_6'),
              description: this.$t('help.trainings.child.description_6'),
              section_1: {
                list: [
                  {
                    title: this.$t('help.trainings.child.section_6.list.text_1.title'),
                    text: this.$t('help.trainings.child.section_6.list.text_1.text'),
                  },
                  {
                    title: this.$t('help.trainings.child.section_6.list.text_2.title'),
                    text: this.$t('help.trainings.child.section_6.list.text_2.text'),
                  },
                  {
                    title: this.$t('help.trainings.child.section_6.list.text_3.title'),
                    text: this.$t('help.trainings.child.section_6.list.text_3.text'),
                  },
                ],
              },
            },
            {
              title: this.$t('help.trainings.child.title_7'),
              img: 'Loss Graph.png',
              section_1: {
                list: [
                  {
                    text: this.$t('help.trainings.child.section_7.list.text_1.text'),
                  },
                  {
                    text: this.$t('help.trainings.child.section_7.list.text_2.text'),
                  },
                  {
                    text: this.$t('help.trainings.child.section_7.list.text_3.text'),
                  },
                  {
                    text: this.$t('help.trainings.child.section_7.list.text_4.text'),
                  },
                ],
              },
            },
          ],
        },
        {
          title: this.$t('help.testings.title'),
          description: this.$t('help.testings.description'),
          second_accordion: true,
          child: [
            {
              title: this.$t('help.testings.child.title_6'),
              description: this.$t('help.testings.child.description_7'),
              img: 'TestingsDir.png',
              description_2: this.$t('help.testings.child.description_8'),
            },
            {
              title: this.$t('help.testings.child.newTesting.title'),
              description: this.$t('help.testings.child.newTesting.description'),
              img: 'TestingsMask.png',
              description_2: this.$t('help.testings.child.newTesting.description_part_2'),
              section_2: {
                title: this.$t('help.testings.child.testmodeSection.title'),
                list: [
                  this.$t('help.testings.child.testmodeSection.list.text_1'),
                  this.$t('help.testings.child.testmodeSection.list.text_2'),
                  this.$t('help.testings.child.testmodeSection.list.text_3'),
                  this.$t('help.testings.child.testmodeSection.list.text_4'),
                  this.$t('help.testings.child.testmodeSection.list.text_5'),
                  this.$t('help.testings.child.testmodeSection.list.text_6'),
                ],
                info: this.$t('help.testings.child.testmodeSection.info'),
              },
              section_3: {
                title: this.$t('help.testings.child.testResourcesSection.title'),
                description: this.$t('help.testings.child.testResourcesSection.description'),
              },
              section_4: {
                title: this.$t('help.testings.child.infoSection.title'),
                description: this.$t('help.testings.child.infoSection.description'),
                img: 'TestingForm-1.png',
                info: this.$t('help.testings.child.infoSection.info'),
              },
              section_5: {
                title: this.$t('help.testings.child.thresholdsSection.title'),
                description: this.$t('help.testings.child.thresholdsSection.description'),
              },
              section_6: {
                title: this.$t('help.testings.child.binarySection.title'),
                description: this.$t('help.testings.child.binarySection.description'),
                img: 'TestingForm-2.png',
              },
              section_7: {
                title: this.$t('help.testings.child.patternsSection.title'),
                description: this.$t('help.testings.child.patternsSection.description'),
                img: 'TestingForm-3.png',
                img_2: 'TestingForm-4.png',
                description_2: this.$t('help.testings.child.patternsSection.description_part_2'),
                img_3: 'TestingForm-5.png',
                info: this.$t('help.testings.child.patternsSection.info'),
                img_4: 'TestingSampleComponent.png',
              },
            },
            {
              title: this.$t('help.testings.child.title_1'),
              description: this.$t('help.testings.child.description_1'),
              img: 'FileStructure.png',
              description_2: this.$t('help.testings.child.description_2'),
              img_1: 'TestingsDirContent.png',
              description_3: this.$t('help.testings.child.description_3'),
              description_4: this.$t('help.testings.child.description_3_part_2'),
            },
            {
              title: this.$t('help.testings.child.title_4'),
              description: this.$t('help.testings.child.description_4'),
              img: 'TestingDatasetScore.png',
              img_1: 'TestingItemMenu.png',
              description_2: this.$t('help.testings.child.description_4_part_2'),
            },
            {
              title: this.$t('help.testings.child.section_error_messages.title'),
              description: this.$t('help.testings.child.section_error_messages.text'),
            },
            {
              title: this.$t('help.testings.child.section_download_test_result.title'),
              img: 'TestingResultsGraphs.png',
              description: this.$t('help.testings.child.section_download_test_result.description'),
              section_2: {
                title: this.$t('help.testings.child.section_download_test_result.section_2.title'),
                description: this.$t('help.testings.child.section_download_test_result.section_2.description'),
                description_2: this.$t('help.testings.child.section_download_test_result.section_2.description_part_2'),
                list: [
                  this.$t('help.testings.child.section_download_test_result.section_2.list.text_1'),
                ],
                info: this.$t('help.testings.child.section_download_test_result.section_2.info'),
              },
            },
          ],
        },
        // {
        //   title: this.$t('help.customers.title'),
        //   description: this.$t('help.customers.description'),
        // },
        {
          title: this.$t('help.file_menager.title'),
          description: this.$t('help.file_menager.description'),
          file_menager: true,
          child: [
            {
              title: this.$t('help.file_menager.child[0].title'),
              description: this.$t('help.file_menager.child[0].description'),
              img: 'FileManager-1.png',
              link: {
                title: 'Settings',
                targetAccIndex: 'acc0',
                targetPanelIndex: 1,
              },
            },
            {
              title: this.$t('help.file_menager.child[1].title'),
              description: this.$t('help.file_menager.child[1].description'),
              img: 'FileManager-2.png',
            },
            {
              title: this.$t('help.file_menager.child[2].title'),
              description: this.$t('help.file_menager.child[2].description'),
              img: 'FileManager-3.png',
            },
            {
              title: this.$t('help.file_menager.child[3].title'),
              description: this.$t('help.file_menager.child[3].description'),
              img: 'FileManager-4.png',
            },
          ],
        },
        {
          title: this.$t('help.users.title'),
          description: this.$t('help.users.description'),
          img: 'Userlist-1.png',
          description_2: this.$t('help.users.description_1'),
          img_1: 'Userlist-2.png',
          description_3: this.$t('help.users.description_2'),
        },
        {
          title: this.$t('help.labelling_tool.title'),
          description: this.$t('help.labelling_tool.description'),
          labelling_tool: true,
          child: [
            {
              title: this.$t('help.labelling_tool.child[0].title'),
              description: this.$t('help.labelling_tool.child[0].description'),
            },
            {
              title: this.$t('help.labelling_tool.child[1].title'),
              description_1: this.$t('help.labelling_tool.child[1].description_1'),
              img_1: 'LabellingToolDashboard.png',
              description_2: this.$t('help.labelling_tool.child[1].description_2'),
              description_3: this.$t('help.labelling_tool.child[1].description_3'),
              description_4: this.$t('help.labelling_tool.child[1].description_4'),
              img_2: 'LabellingTool-1.png',
              description_5: this.$t('help.labelling_tool.child[1].description_5'),
              img_3: 'LabellingTool-3.png',
              img_4: 'LabellingTool-2.png',
              description_6: this.$t('help.labelling_tool.child[1].description_6'),
            },
            {
              title: this.$t('help.labelling_tool.child[2].title'),
              description_7: this.$t('help.labelling_tool.child[2].description_7'),
              img_5: 'LabellingTool-4.png',
              description_8: this.$t('help.labelling_tool.child[2].description_8'),
              img_6: 'LabellingTool-5.png',
              description_9: this.$t('help.labelling_tool.child[2].description_9'),
              description_10: this.$t('help.labelling_tool.child[2].description_10'),
              img_7: 'LabellingTool-6.png',
              description_11: this.$t('help.labelling_tool.child[2].description_11'),
            },
          ],
        },
      ],
      openedPanel: {
        acc0: [],
        acc1: [],
      },
    };
  },
  created() {
    this.userRoles = this.$store.getters['user/roles'];
  },
  methods: {
    closeAllPanels(accIndex) {
      this.openedPanel[accIndex] = [];
    },
    openPanel(accIndex, index) {
      if (!this.openedPanel[accIndex].includes(index)) {
        this.openedPanel[accIndex].push(index);
      }
    },
    closePanel(accIndex, index) {
      if (this.openedPanel[accIndex].includes(index)) {
        this.openedPanel[accIndex].splice(index, 1);
      }
    },
    scrollTo(accIndex, panelIndex) {
      this.openPanel(accIndex, panelIndex);
      setTimeout(() => {
        this.$vuetify.goTo(`#${accIndex}_${panelIndex}`);
      }, 500);
    },
    imageUrl(item) {
      let icon = '';
      if (item) {
        // eslint-disable-next-line
        icon = require(`@/assets/images/faq/${item}`);
      }
      return icon;
    },
    checkList(item, index) {
      // eslint-disable-next-line
      const data = item['section_' + index] ? item['section_' + index] : null;
      if (data && data.list && data.list.length) {
        return true;
      }
      return false;
    },
    getImageWidthClass(imgSrc) {
      const key = this.imageUrl(imgSrc);
      return this.imageWidths[key] ? 'w-75' : '';
    },
    checkImageWidth(imgSrc) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          this.$set(this.imageWidths, imgSrc, img.naturalWidth > 1000);
          resolve();
        };
        img.onerror = () => {
          reject(new Error(`Img src error: ${imgSrc}`));
        };
        img.src = imgSrc;
      });
    },
  },
  mounted() {
    const imageLoadPromises = [];
    const processItem = (item) => {
      ['img', 'img_1', 'img_2'].forEach((key) => {
        if (item[key]) {
          imageLoadPromises.push(this.checkImageWidth(this.imageUrl(item[key])));
        }
      });
      if (item.child) {
        item.child.forEach((childItem) => processItem(childItem));
      }
    };

    [...this.first_step, ...this.data].forEach(processItem);

    Promise.all(imageLoadPromises)
      .then(() => {
        this.$forceUpdate();
      })
      .catch((error) => {
        console.error('Error at processing images:', error.message);
      });
  },
};
</script>
<style lang="scss">
.blue-text {
  color: #4472c4;
}
.inside-list {
  li {
    list-style: disc outside none;
    display: list-item;
    margin-left: 1em;
    span {
      color: #d30f47;
      font-weight: 500;
    }
  }
}
.start-text, .retry-text {
  color: #c00000;
  display: inline-block;
  font-weight: bold;
}
.download-text {
  color: #00b050;
  display: inline-block;
  font-weight: bold;
}
strong {
  display: inline-block;
}
.margin-br > br{
  content: '';
  display: block;
  margin-bottom: 1rem;
}
.second-panels .v-expansion-panel-header{
  background: #f6f6f6;
}
.v-expansion-panel-header--active {
  font-weight: bold;
}
.v-expansion-panel-content {
  .img-responsive {
    margin-top: 10px;
    max-width: 100%;
  }
  .w-75 {
    max-width: 75%;
  }
}
</style>
